import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY, POST } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue"; // import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

import ManagePaymentMode from "@/view/pages/partials/Manage-Payment-Mode.vue";
import { ErrorEventBus } from "@/core/lib/message.lib";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [ValidationMixin],
  props: {
    detail: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      formLoading: false,
      pageLoading: false,
      paymentMinDate: null,
      managePaymentModeDialog: false,
      paymentModeList: [],
      paymentCreate: {
        received: null,
        payment_mode: null,
        transaction_id: null,
        admin_notes: null,
        payment_date: null,
        documents: null,
        barcode: null
      }
    };
  },
  watch: {
    detail: {
      deep: true,
      handler: function handler() {
        this.paymentCreate.received = this.detail.total - this.detail.paid_amount;
      }
    }
  },
  methods: {
    setPaymentDate: function setPaymentDate(param) {
      this.paymentCreate.payment_date = param;
    },
    updateFiles: function updateFiles(param) {
      this.paymentCreate.documents = param;
    },
    onSubmit: function onSubmit() {
      var _this = this;

      if (!_this.$refs.paymentForm.validate()) {
        return false;
      }

      var formData = {
        action: "mark_as_paid",
        invoice: _this.lodash.toSafeInteger(_this.detail.id) || 0,
        amount: _this.lodash.toNumber(_this.paymentCreate.received) || 0.0,
        payment_date: _this.paymentCreate.payment_date || null,
        admin_note: _this.paymentCreate.admin_notes || null,
        transaction_id: _this.paymentCreate.transaction_id || null,
        payment_mode: _this.lodash.toSafeInteger(_this.paymentCreate.payment_mode) || 0,
        documents: _this.paymentCreate.documents || []
      };

      var pendingAmount = _this.lodash.toNumber(_this.detail.pending_amount);

      if (pendingAmount < formData.amount) {
        ErrorEventBus.$emit("update:error", "Amount received must be less than or equal to ".concat(pendingAmount));
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(POST, {
        url: "payment",
        data: formData
      }).then(function () {
        _this.$emit("close", true);

        _this.$emit("success", true);
      }).catch(function (error) {
        _this.logError(error);

        _this.$emit("error", true);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    getAttributes: function getAttributes(attribute) {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(QUERY, {
        url: "payment/options",
        data: {
          attribute: attribute
        }
      }).then(function (_ref) {
        var data = _ref.data;

        if (_this.lodash.isEmpty(data) === false) {
          if (_this.lodash.isEmpty(data.payment_modes) === false) {
            _this.paymentModeList = data.payment_modes;
          }

          if (data.barcode) {
            _this.paymentCreate.barcode = data.barcode;
          }
        }
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  mounted: function mounted() {
    this.getAttributes(["payment_modes", "barcode"]);
    this.paymentMinDate = moment().format("YYYY-MM-DD");
  },
  components: {
    Dialog: Dialog,
    DatePicker: DatePicker,
    FileTemplate: FileTemplate,
    // editor: TinyMCE,
    ManagePaymentMode: ManagePaymentMode
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 60);
    }
  }
};